<template>
  <div class="dolligo-wrap">
    <div class="count-bar">현재 인사교류 희망지역 {{ applyCount }}</div>
    <header>
      <dolligo-top :propMember="member"></dolligo-top>
      <router-link :to="{ path: `/dolligo/main/search` }" class="search-btn" >
        <span class="icon"></span>교류자 검색 하기
      </router-link>

      <swiper class="swiper filter-swiper" :options="swiperOption">
        <swiper-slide v-if="query.mb_nick"><a href="javascript:void(0)"
          @click="query.mb_nick = ''; changeFilter();">{{ query.mb_nick }}(닉네임)</a></swiper-slide>
        <swiper-slide v-if="query.mb_8"><a href="javascript:void(0)"
            @click="query.mb_8 = ''; query.mb_17 = ''; changeFilter();">{{ query.mb_8 }}(현재)</a></swiper-slide>
        <swiper-slide v-if="query.mb_17"><a href="javascript:void(0)"
            @click="query.mb_17 = ''; changeFilter();">{{ this.currentOfficeName }}(현재)</a></swiper-slide>
        <swiper-slide v-if="query.mb_9"><a href="javascript:void(0)"
            @click="query.mb_9 = ''; query.mb_10 = ''; changeFilter();">{{ this.currentCityName }}(현재)</a></swiper-slide>
        <swiper-slide v-if="query.mb_10"><a href="javascript:void(0)"
            @click="query.mb_10 = ''; changeFilter();">{{ this.currentDistrictName }}(현재)</a></swiper-slide>
        <swiper-slide v-if="query.mb_18"><a href="javascript:void(0)"
            @click="query.mb_18 = ''; query.mb_19 = ''; changeFilter();">{{ query.mb_18 }}(희망)</a></swiper-slide>
        <swiper-slide v-if="query.mb_19"><a href="javascript:void(0)"
            @click="query.mb_19 = ''; changeFilter();">{{ this.hopeOfficeName }}(희망)</a></swiper-slide>
        <swiper-slide v-if="query.hopeCity"><a href="javascript:void(0)"
            @click="query.hopeCity = ''; query.hopeDistrict = ''; changeFilter();">{{ this.hopeCityName }}(희망)</a>
        </swiper-slide>
        <swiper-slide v-if="query.hopeDistrict"><a href="javascript:void(0)"
            @click="query.hopeDistrict = ''; changeFilter();">{{ this.hopeDistrictName }}(희망)</a></swiper-slide>
        <swiper-slide v-if="query.mb_39"><a href="javascript:void(0)"
            @click="query.mb_39 = ''; changeFilter();">{{ this.timeJobName }}</a></swiper-slide>
        <swiper-slide v-if="query.mb_1"><a href="javascript:void(0)"
            @click="query.mb_1 = ''; changeFilter();">{{ this.jobName }}</a></swiper-slide>
        <swiper-slide v-if="query.mb_2"><a href="javascript:void(0)"
            @click="query.mb_2 = ''; changeFilter();">{{ this.rankName }}</a></swiper-slide>
        <swiper-slide v-if="query.mb_27 == 1"><a href="javascript:void(0)"
            @click="query.mb_27 = ''; changeFilter();">일방전출</a></swiper-slide>
      </swiper>

    </header>
    <div class="content-wrap bg-white">
      <ul class="home-list">
        <li v-if="applyCount != searchApplyCount">
          <h2 class="list-tit"><strong>{{ searchApplyCount }}</strong>건의 검색 결과</h2>
        </li>
        <li v-for="apply in applyList" v-bind:key="apply.wr_id">
          <router-link :to="{ path: `/dolligo/member/${apply.mb_id}` }" class="link-btn">
            <span :class="`icon face${apply.faceType}`"></span>
            <span class="infor-row">
              <strong>{{ apply.nickName }}</strong>
              <span class="value">{{ apply.whereJob }}</span>
              <span class="value">{{ apply.jobType }}</span>
              <span class="value">{{ apply.jobPosition }}</span>
              <span class="value" v-if="apply.mb_sex == 'M' || apply.mb_sex == 'F'">{{ apply.mb_sex == 'M' ? '남성' :
                '여성' }}</span>
            </span>
            <span class="district-row">
              <span class="now">{{ apply.whereFrom }}</span>
              <span class="goal">{{ apply.whereTo }}</span>
            </span>
          </router-link>
        </li>
        <infinite-loading :identifier="infiniteId" @infinite="getApplyList" ref="infiniteLoading"></infinite-loading>
      </ul>
    </div>
    <dolligo-footer propType="dolligoMain"></dolligo-footer>
  </div>
</template>

<script>
import numeral from 'numeral';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import mixin from '../../../mixin';
import DolligoTop from '../../common/DolligoTop.vue';
import Vue from 'vue'
import DolligoFooter from '../../common/DolligoFooter.vue';

export default {
  name: "dolligoMain",
  mixins: [mixin],
  components: {
    Swiper,
    SwiperSlide,
    DolligoTop,
    DolligoFooter
  },
  data: function () {
    return {
      applyCount: window.main.applyCount,
      searchApplyCount: window.main.searchApplyCount,
      applyList: window.main.applyList,
      query: {
        page: window.main.query.page,
        limit: 10,
        mb_8: window.mainSearch.currentWorkInfo.mb_8, // 현재 국가직 or 지방직
        mb_9: window.mainSearch.currentWorkInfo.mb_9, // 현재 광역시도
        mb_10: window.mainSearch.currentWorkInfo.mb_10, // 현재 시군구
        mb_17: window.mainSearch.currentWorkInfo.mb_17, // 현재 행정처
        mb_18: window.mainSearch.hopeWorkInfo.mb_8, // 희망 국가직 or 지방직
        hopeCity: window.mainSearch.hopeWorkInfo.mb_9, // 희망 광역시도
        hopeDistrict: window.mainSearch.hopeWorkInfo.mb_10, // 희망 시군구
        mb_19: window.mainSearch.hopeWorkInfo.mb_17, // 희망 행정처
        mb_1: window.mainSearch.currentWorkInfo.mb_1, // 교류자의 직렬
        mb_2: window.mainSearch.currentWorkInfo.mb_2, // 교류자의 직군
        mb_39: window.mainSearch.currentWorkInfo.mb_39, // 시간 선택제 공무원
        mb_27: window.mainSearch.currentWorkInfo.mb_27, // 일방 전출
        mb_nick: window.mainSearch.mb_nick, // 닉네임 검색
      },
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 4,
        freeMode: true,
      },
      currentCityName: '',
      currentDistrictName: '',
      currentOfficeName: '',
      hopeCityName: '',
      hopeDistrictName: '',
      hopeOfficeName: '',
      timeJobName: '',
      jobName: '',
      rankName: '',
      infiniteId: +new Date(),
      member: Vue.prototype.MEMBER
    }
  },
  created: async function () {
    this.init();
  },
  methods: {
    init: async function () {
      if (this.query.mb_9) this.currentCityName = await this.getCityName(this.query.mb_9);
      if (this.query.mb_10) this.currentDistrictName = await this.getDistrictName(this.query.mb_10);
      if (this.query.mb_17) this.currentOfficeName = await this.getOfficetName(this.query.mb_17);
      if (this.query.hopeCity) this.hopeCityName = await this.getCityName(this.query.hopeCity);
      if (this.query.hopeDistrict) this.hopeDistrictName = await this.getDistrictName(this.query.hopeDistrict);
      if (this.query.mb_19) this.hopeOfficeName = await this.getOfficetName(this.query.mb_19);
      if (this.query.mb_39) this.timeJobName = '시간선택제';
      if (this.query.mb_1) this.jobName = await this.getJobName(this.query.mb_1);
      if (this.query.mb_2) this.rankName = await this.getRankName(this.query.mb_2);
      let response = await axios.get('/api/apply/count');
      this.applyCount = numeral(response.data.applyCount).format('0,0');
    },
    async changeFilter() {
      this.query.page = 1;
      this.applyList = [];
      this.infiniteId += 1;

      let currentWorkInfo = {
        mb_8: this.query.mb_8, // 국가직 or 지방직
        mb_17: this.query.mb_17, // 행정처
        mb_9: this.query.mb_9, // 광역시도
        mb_10: this.query.mb_10, // 시군구
        mb_1: this.query.mb_1, // 직렬
        mb_2: this.query.mb_2, // 직급
        mb_39: this.query.mb_39, // 시간선택제
        mb_27: this.query.mb_27, // 일방전출
      }
      window.mainSearch.currentWorkInfo = currentWorkInfo;
      window.mainSearch.mb_nick = this.query.mb_nick;

      let hopeWorkInfo = {
        mb_8: this.query.mb_18, // 국가직 or 지방직
        mb_17: this.query.mb_19, // 행정처
        mb_9: this.query.hopeCity, // 광역시도
        mb_10: this.query.hopeDistrict, // 시군구
      }
      window.mainSearch.hopeWorkInfo = hopeWorkInfo;

      let config = {};
      config.params = currentWorkInfo;
      config.params.hopeCity = hopeWorkInfo.mb_9;
      config.params.hopeDistrict = hopeWorkInfo.mb_10;
      config.params.mb_18 = hopeWorkInfo.mb_8;
      config.params.mb_19 = hopeWorkInfo.mb_17;
      window.mainSearch.config = config;

      // infinite-loading 상태 리셋
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.$emit('reset');
      }

      // 검색 수행
      // 무한 스크롤 상태가 초기화된 후 제품 목록 로드
      await this.getApplyList({
        loaded: () => {},
        complete: () => {}
      });
    },
    // 지원자 리스트 및 전체 카운트
    getApplyList: async function ($state) {
      let config = {
        params: this.query
      };

      let response = await axios.get('/api/apply', config);
      this.searchApplyCount = numeral(response.data.count).format('0,0');
      window.main.searchApplyCount = this.searchApplyCount;
      if (response.data.rows.length == 0) {
        $state.complete();
      } else {
        this.query.page = parseInt(this.query.page) + 1;

        // 중복되지 않은 wr_id만 추가
        const newRows = response.data.rows.filter(row => 
            !this.applyList.some(existing => existing.wr_id === row.wr_id)
        );

        // 중복이 제거된 항목만 추가
        this.applyList.push(...newRows);        

        $state.loaded();
      }
    },
  }
}
</script>


<template>
  <li>
    <select v-model="office" @change="onDataChanged()">
      <option value="">현재 행정처 선택</option>
      <option
        v-for="item in offices"
        v-bind:key="item.grd3_cd"
        v-bind:value="item.grd3_cd"
      >
        {{ item.grd3_nm }}
      </option>
    </select>
  </li>
</template>

<script>
import mixin from "../../mixin";
import axios from "axios";

export default {
  name: "workInfo",
  mixins: [mixin],
  props: {
    officeCode: String,
  },
  data: function () {
    return {
      office: this.officeCode,
      offices: [],
    };
  },
  watch: {
    // 부모에서 전달된 officeCode 변경 시 office에 반영
    officeCode(newVal) {
      this.office = newVal;
    },
  },
  created: async function () {
    // 행정처
    this.offices = await axios.get("/api/offices", this.axiosConfig);
    this.offices = this.offices.data;
  },
  methods: {
    onDataChanged() {
      this.$emit("input", this.office); // input 이벤트 발생
    },
  },
};
</script>


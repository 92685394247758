<template>
  <div class="dolligo-wrap">
    <div class="layer-wrap">
      <header class="fixed">
        <router-link :to="{ name: `dolligoMain` }" class="icon close-btn">
          닫기
        </router-link>
        <div class="right-btns">
          <a
            href="javascript:void(0);"
            v-on:click="initSearch()"
            class="txt-btn"
            :class="{ on: hasAnyValue() }"
            >검색 초기화</a
          >
        </div>
      </header>
      <div class="content-wrap">
        <div class="write-wrap">
          <div class="list-wrap">
            <h2>닉네임 검색하기</h2>
            <div class="input-wrap">
              <input
                type="search"
                class="search-input"
                placeholder="닉네임을 입력해 주세요"
                v-model="mb_nick"
                @keyup.enter="searchNick()"
              />
              <button
                type="button"
                class="icon search-button"
                @click="searchNick()"
              >
                검색
              </button>
            </div>
          </div>
          <hr />

          <div :class="{ off: mb_nick }">
            <!-- 닉네임 검색 선택시 여기에 class="off" 추가 -->
            <div class="list-wrap" v-if="searchHistories.length > 0">
              <h2 v-if="searchHistories.length > 0">최근 검색 리스트</h2>
              <ul class="recent-list" v-if="searchHistories.length > 0">
                <li
                  v-for="element in searchHistories"
                  v-bind:key="element.timestamp"
                >
                  <button
                    class="recent"
                    @click.stop="selSearchHistory(element)"
                  >
                    <button
                      class="icon clear-button"
                      type="button"
                      @click.stop="delSearchHistory(element)"
                    >
                      삭제
                    </button>
                    <dl>
                      <dt>희망</dt>
                      <dd style="height: 35px">
                        <span v-if="element.hopeWorkInfo.mb_8">{{
                          element.hopeWorkInfo.mb_8
                        }}</span>
                        <span
                          v-if="
                            element.hopeWorkInfo.mb_8 &&
                            (element.hopeWorkInfo.hopeCityName ||
                              element.hopeWorkInfo.hopeDistrictName ||
                              element.hopeWorkInfo.hopeOfficeName)
                          "
                        >
                          /
                        </span>

                        <span v-if="element.hopeWorkInfo.hopeCityName">{{
                          element.hopeWorkInfo.hopeCityName
                        }}</span>
                        <span
                          v-if="
                            element.hopeWorkInfo.hopeCityName &&
                            (element.hopeWorkInfo.hopeDistrictName ||
                              element.hopeWorkInfo.hopeOfficeName)
                          "
                        >
                          /
                        </span>

                        <span v-if="element.hopeWorkInfo.hopeDistrictName">{{
                          element.hopeWorkInfo.hopeDistrictName
                        }}</span>
                        <span
                          v-if="
                            element.hopeWorkInfo.hopeDistrictName &&
                            element.hopeWorkInfo.hopeOfficeName
                          "
                        >
                          /
                        </span>

                        <span v-if="element.hopeWorkInfo.hopeOfficeName">{{
                          element.hopeWorkInfo.hopeOfficeName
                        }}</span>
                      </dd>
                    </dl>
                    <dl>
                      <dt>현재</dt>
                      <dd>
                        <span v-if="element.currentWorkInfo.mb_8">{{
                          element.currentWorkInfo.mb_8
                        }}</span>
                        <span
                          v-if="
                            element.currentWorkInfo.mb_8 &&
                            (element.currentWorkInfo.currentDistrictName ||
                              element.currentWorkInfo.currentCityName ||
                              element.currentWorkInfo.currentOfficeName)
                          "
                        >
                          •
                        </span>
                        <span
                          v-if="element.currentWorkInfo.currentDistrictName"
                          >{{
                            element.currentWorkInfo.currentDistrictName
                          }}</span
                        >
                        <span
                          v-if="
                            element.currentWorkInfo.currentDistrictName &&
                            (element.currentWorkInfo.currentCityName ||
                              element.currentWorkInfo.currentOfficeName)
                          "
                        >
                          •
                        </span>
                        <span v-if="element.currentWorkInfo.currentCityName">{{
                          element.currentWorkInfo.currentCityName
                        }}</span>
                        <span
                          v-if="
                            element.currentWorkInfo.currentCityName &&
                            element.currentWorkInfo.currentOfficeName
                          "
                        >
                          •
                        </span>
                        <span
                          v-if="element.currentWorkInfo.currentOfficeName"
                          >{{ element.currentWorkInfo.currentOfficeName }}</span
                        >
                      </dd>
                      <dd>
                        <span v-if="element.currentWorkInfo.jobName">{{
                          element.currentWorkInfo.jobName
                        }}</span>
                        <span
                          v-if="
                            element.currentWorkInfo.jobName &&
                            (element.currentWorkInfo.rankName ||
                              element.currentWorkInfo.timeJobName)
                          "
                        >
                          •
                        </span>
                        <span v-if="element.currentWorkInfo.rankName">{{
                          element.currentWorkInfo.rankName
                        }}</span>
                        <span
                          v-if="
                            element.currentWorkInfo.rankName &&
                            element.currentWorkInfo.timeJobName
                          "
                        >
                          •
                        </span>
                        <span v-if="element.currentWorkInfo.timeJobName">{{
                          element.currentWorkInfo.timeJobName
                        }}</span>
                      </dd>
                    </dl>
                  </button>
                </li>
              </ul>
            </div>
            <hr v-if="searchHistories.length > 0" />
            <div class="list-wrap">
              <h2>교류자의 현재 근무지, 직렬, 직급</h2>
              <ul>
                <depart
                  :departCode="currentWorkInfo"
                  :isShowNoSelect="false"
                  @change.native="search()"
                ></depart>
                <office
                  v-if="currentWorkInfo.mb_8 == '국가직'"
                  v-model="currentWorkInfo.mb_17"
                  :officeCode="currentWorkInfo.mb_17"
                  @change.native="search()"
                ></office>
                <addr
                  :addrCode="currentWorkInfo"
                  @change.native="search()"
                ></addr>
              </ul>
              <ul style="padding-top: 7px">
                <job-rank
                  v-model="currentWorkInfo"
                  :jobRankCode="currentWorkInfo"
                  @change.native="search()"
                ></job-rank>
              </ul>
              <ul>
                <li>
                  <div class="check-wrap match">
                    <label for="time" class="form-check-label">
                      <input
                        id="time"
                        type="checkbox"
                        class="form-check-input"
                        v-model="currentWorkInfo.mb_39"
                        true-value="1"
                        false-value=""
                        @change="search()"
                      />시간선택제 공무원
                      <span class="form-check-sign">
                        <span class="check"> </span>
                      </span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div class="list-wrap">
              <h2>교류자의 희망 근무지</h2>
              <ul>
                <depart
                  :departCode="hopeWorkInfo"
                  :isShowNoSelect="false"
                  @change.native="search()"
                ></depart>
                <office
                  v-if="hopeWorkInfo.mb_8 == '국가직'"
                  v-model="hopeWorkInfo.mb_17"
                  :officeCode="hopeWorkInfo.mb_17"
                  @change.native="search()"
                ></office>
                <addr :addrCode="hopeWorkInfo" @change.native="search()"></addr>
              </ul>
            </div>
            <!-- <div class="list-wrap">
              <h2>교류자의 직렬, 직급</h2>
              <ul>
                <job-rank
                  v-model="currentWorkInfo"
                  :jobRankCode="currentWorkInfo"
                  @change.native="search()"
                ></job-rank>
              </ul>
              <ul>
                <li>
                  <div class="check-wrap match">
                    <label for="time" class="form-check-label">
                      <input
                        id="time"
                        type="checkbox"
                        class="form-check-input"
                        v-model="currentWorkInfo.mb_39"
                        true-value="1"
                        false-value=""
                        @change="search()"
                      />시간선택제 공무원
                      <span class="form-check-sign">
                        <span class="check"> </span>
                      </span>
                    </label>
                  </div>
                </li>
              </ul>
            </div> -->
            <div class="list-wrap">
              <h2>상세 조건 추가<span class="icon premium-i"></span></h2>
              <ul>
                <li>
                  <label
                    class="submit-btn"
                    :class="{ on: currentWorkInfo.mb_27 == '1' }"
                    ><input
                      type="checkbox"
                      true-value="1"
                      false-value=""
                      v-model="currentWorkInfo.mb_27"
                      style="-webkit-appearance: none; position: relative"
                      v-on:change="
                        checkPremium();
                        search();
                      "
                    />
                    일방 전출
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <button
            class="submit-btn on fixed"
            id="btn-submit"
            v-on:click="goApplyList()"
          >
            {{ resuleMessage }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Addr from "../../common/Addr.vue";
import Depart from "../../common/Depart.vue";
import JobRank from "../../common/JobRank.vue";
import Office from "../../common/Office.vue";
import axios from "axios";
import mixin from "../../../mixin";

export default {
  components: {
    Depart,
    Office,
    Addr,
    JobRank,
  },
  mixins: [mixin],
  name: "dolligoMainSearch",
  data: function () {
    return {
      mb_nick: window.mainSearch.mb_nick,
      currentWorkInfo: { ...window.mainSearch.currentWorkInfo },
      hopeWorkInfo: { ...window.mainSearch.hopeWorkInfo },
      resuleMessage: "검색하기",
      applyData: null,
      config: { ...window.mainSearch.config },
      searchHistories: [],
      STORAGE_KEY: "searchHistory",
    };
  },
  async created() {
    this.search();
    this.searchHistories = await this.getSearchHistory();
    console.log(this.searchHistories);
  },
  methods: {
    checkPremium: function () {
      if (this.MEMBER.mb_level < 5) {
        this.currentWorkInfo.mb_27 = "";
        if (
          confirm("프리미엄 멤버십 회원만 검색 가능합니다\n가입하겠습니까?")
        ) {
          this.$router.push({ name: "dolligoPremiumIntro" });
        }
      }
    },
    search: async function () {
      if (this.mb_nick) {
        let mb_nick = this.mb_nick;
        this.initSearch();
        this.mb_nick = mb_nick;
      }
      this.config.params = this.currentWorkInfo;
      this.config.params.hopeCity = this.hopeWorkInfo.mb_9;
      this.config.params.hopeDistrict = this.hopeWorkInfo.mb_10;
      this.config.params.mb_18 = this.hopeWorkInfo.mb_8;
      this.config.params.mb_19 = this.hopeWorkInfo.mb_17;
      this.config.params.mb_nick = this.mb_nick;

      let response = await axios.get("/api/apply", this.config);
      this.applyData = response.data;
      this.resuleMessage = `검색 결과 보기(${this.applyData.count.toLocaleString()}건)`;
    },
    async searchNick() {
      await this.search();
      await this.goApplyList();
    },
    async goApplyList() {
      // 검색 조건 window 변수에 저장
      window.mainSearch.currentWorkInfo = this.currentWorkInfo;
      window.mainSearch.hopeWorkInfo = this.hopeWorkInfo;
      window.mainSearch.mb_nick = this.mb_nick;
      window.mainSearch.config = this.config;

      // main data 초기화
      window.main.applyCount = 0;
      window.main.searchApplyCount = 0;
      window.main.applyList = [];
      window.main.query.page = 1;

      if (
        !this.mb_nick &&
        (Object.values(this.currentWorkInfo).some((value) => value !== "") ||
          Object.values(this.hopeWorkInfo).some((value) => value !== ""))
      ) {
        this.addSearchHistory({
          currentWorkInfo: window.mainSearch.currentWorkInfo,
          hopeWorkInfo: window.mainSearch.hopeWorkInfo,
        });
      }

      if (this.$route.path !== "/dolligo/main") {
        this.$router.push({ name: "dolligoMain" });
      }
    },
    initSearch: function () {
      for (let key in this.currentWorkInfo) {
        this.currentWorkInfo[key] = "";
      }
      for (let key in this.hopeWorkInfo) {
        this.hopeWorkInfo[key] = "";
      }
      this.mb_nick = "";
      this.resuleMessage = "검색하기";
      this.applyData = null;
      this.config = {};
    },

    hasAnyValue() {
      // 두 객체의 모든 값을 배열로 합친 후, 빈 값이 아닌 항목이 있는지 확인
      return [
        ...Object.values(this.currentWorkInfo),
        ...Object.values(this.hopeWorkInfo),
        this.mb_nick,
      ].some((value) => value !== "");
    },

    // `localStorage`에서 검색 기록을 가져오는 함수
    getSearchHistory() {
      const storedData = localStorage.getItem(this.STORAGE_KEY);
      if (!storedData) {
        return [];
      }

      // 저장된 데이터에서 timestamp가 있는 객체만 필터링
      const parsedData = JSON.parse(storedData);
      const validData = parsedData.filter((item) => item.timestamp);

      // 유효하지 않은 데이터가 있을 경우 업데이트
      if (parsedData.length !== validData.length) {
        console.warn("Invalid objects found and removed.");
        this.saveSearchHistory(validData);
      }

      return validData;
    },

    // 검색 기록을 `localStorage`에 저장하는 함수
    saveSearchHistory(data) {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
    },

    // 중복 검사 후 검색 기록을 추가하는 함수
    async addSearchHistory(newSearch) {
      // 현재 시간을 고유 키로 추가
      newSearch.timestamp = Date.now();

      // currentWorkInfo에 추가 데이터 할당
      if (newSearch.currentWorkInfo.mb_9) {
        newSearch.currentWorkInfo.currentCityName = await this.getCityName(
          newSearch.currentWorkInfo.mb_9
        );
      }
      if (newSearch.currentWorkInfo.mb_10) {
        newSearch.currentWorkInfo.currentDistrictName =
          await this.getDistrictName(newSearch.currentWorkInfo.mb_10);
      }
      if (newSearch.currentWorkInfo.mb_17) {
        newSearch.currentWorkInfo.currentOfficeName = await this.getOfficetName(
          newSearch.currentWorkInfo.mb_17
        );
      }
      if (newSearch.currentWorkInfo.mb_1) {
        newSearch.currentWorkInfo.jobName = await this.getJobName(
          newSearch.currentWorkInfo.mb_1
        );
      }
      if (newSearch.currentWorkInfo.mb_2) {
        newSearch.currentWorkInfo.rankName = await this.getRankName(
          newSearch.currentWorkInfo.mb_2
        );
      }
      if (newSearch.currentWorkInfo.mb_39) {
        newSearch.currentWorkInfo.timeJobName = "시간선택제";
      }
      newSearch.currentWorkInfo.summary = [
        newSearch.currentWorkInfo.currentOfficeName || "",
        newSearch.currentWorkInfo.currentCityName || "",
        newSearch.currentWorkInfo.currentDistrictName || "",
      ].filter(Boolean);

      // hopeWorkInfo에 추가 데이터 할당
      if (newSearch.hopeWorkInfo.mb_9) {
        newSearch.hopeWorkInfo.hopeCityName = await this.getCityName(
          newSearch.hopeWorkInfo.mb_9
        );
      }
      if (newSearch.hopeWorkInfo.mb_10) {
        newSearch.hopeWorkInfo.hopeDistrictName = await this.getDistrictName(
          newSearch.hopeWorkInfo.mb_10
        );
      }
      if (newSearch.hopeWorkInfo.mb_17) {
        newSearch.hopeWorkInfo.hopeOfficeName = await this.getOfficetName(
          newSearch.hopeWorkInfo.mb_17
        );
      }
      newSearch.hopeWorkInfo.summary = [
        newSearch.hopeWorkInfo.hopeOfficeName || "",
        newSearch.hopeWorkInfo.hopeCityName || "",
        newSearch.hopeWorkInfo.hopeDistrictName || "",
      ].filter(Boolean);

      // 현재 저장된 검색 기록 가져오기
      const searchHistory = await this.getSearchHistory();

      // 중복 여부 확인
      const existingIndex = searchHistory.findIndex(
        (item) =>
          JSON.stringify(item.currentWorkInfo) ===
            JSON.stringify(newSearch.currentWorkInfo) &&
          JSON.stringify(item.hopeWorkInfo) ===
            JSON.stringify(newSearch.hopeWorkInfo)
      );

      if (existingIndex !== -1) {
        // 기존 데이터가 있으면 해당 항목 삭제
        searchHistory.splice(existingIndex, 1);
      }

      // 새 검색 데이터를 맨 앞에 추가
      searchHistory.unshift(newSearch);

      // 업데이트된 검색 기록을 `localStorage`에 저장
      this.saveSearchHistory(searchHistory);
    },

    // 검색 기록 선택 시 현재 데이터에 매핑하여 설정하는 함수
    async selSearchHistory(element) {
      if (!element) {
        console.error("Element is undefined");
        return;
      }

      // currentWorkInfo 초기화 후 매핑
      Object.keys(this.currentWorkInfo).forEach((key) => {
        this.currentWorkInfo[key] = "";
      });
      if (element.currentWorkInfo) {
        Object.keys(this.currentWorkInfo).forEach((key) => {
          if (key in element.currentWorkInfo) {
            this.currentWorkInfo[key] = element.currentWorkInfo[key];
          }
        });
      }

      // hopeWorkInfo 초기화 후 매핑
      Object.keys(this.hopeWorkInfo).forEach((key) => {
        this.hopeWorkInfo[key] = "";
      });
      if (element.hopeWorkInfo) {
        Object.keys(this.hopeWorkInfo).forEach((key) => {
          if (key in element.hopeWorkInfo) {
            this.hopeWorkInfo[key] = element.hopeWorkInfo[key];
          }
        });
      }

      // config 초기화 후 매핑
      Object.keys(this.config).forEach((key) => {
        this.config[key] = "";
      });
      if (element.config) {
        Object.keys(this.config).forEach((key) => {
          if (key in element.config) {
            this.config[key] = element.config[key];
          }
        });
      } else {
        console.warn("Config is missing in the selected element");
      }

      // currentWorkInfo 매핑
      if (element.currentWorkInfo) {
        Object.keys(this.currentWorkInfo).forEach((key) => {
          if (key in element.currentWorkInfo) {
            this.currentWorkInfo[key] = element.currentWorkInfo[key];
          }
        });
      }

      // hopeWorkInfo 매핑
      if (element.hopeWorkInfo) {
        Object.keys(this.hopeWorkInfo).forEach((key) => {
          if (key in element.hopeWorkInfo) {
            this.hopeWorkInfo[key] = element.hopeWorkInfo[key];
          }
        });
      }

      // config 매핑
      if (element.config) {
        Object.keys(this.config).forEach((key) => {
          if (key in element.config) {
            this.config[key] = element.config[key];
          }
        });
      } else {
        console.warn("Config is missing in the selected element");
      }

      console.log(this.currentWorkInfo);
      await this.search();
    },

    // 히스토리 삭제
    delSearchHistory(element) {
      let timestamp = element.timestamp;
      if (!timestamp) {
        // element.timestamp가 없을 경우 해당 객체를 삭제
        // console.error("Timestamp is undefined. Removing invalid object...");

        // 현재 저장된 검색 기록 가져오기
        const searchHistory = this.getSearchHistory();

        // 해당 element를 제외한 나머지 객체들로 새 배열 생성
        const updatedHistory = searchHistory.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(element)
        );

        // 업데이트된 검색 기록을 localStorage에 저장
        this.saveSearchHistory(updatedHistory);

        // 검색 기록 다시 가져오기
        this.searchHistories = this.getSearchHistory();
      } else {
        // 현재 저장된 검색 기록 가져오기
        const searchHistory = this.getSearchHistory();

        // 해당 timestamp를 가진 항목 제거
        const updatedHistory = searchHistory.filter(
          (item) => item.timestamp !== timestamp
        );

        // 업데이트된 검색 기록을 localStorage에 저장
        this.saveSearchHistory(updatedHistory);

        // 검색 기록 다시 가져오기
        this.searchHistories = this.getSearchHistory();
      }
    },
  },
};
</script>
